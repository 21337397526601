import axios from "axios"
import CalendarTag from "packs/models/CalendarTag"
import Util from "packs/utils/Util"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import Event from "packs/models/Event"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import NotificationControl from "packs/utils/NotificationControl"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import UserSetting from "packs/models/UserSetting"
import RoomSetting from "packs/models/RoomSetting"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomStorage from "packs/models/RoomStorage"
import LocationTag from "packs/models/LocationTag"
import Notice from "./Notice"
import Const from "packs/utils/Const"
import CalendarUtil from "packs/utils/CalendarUtil"
import Appointment from "./Appointment"
import Room from "./Room"
import { reactive } from "vue"

interface CalendarManager {
    ctList: CalendarTag[] // Google、MSから取得してきた全てのArray<CalendarTag>
    edittingMag: MeetingAttendeesGroup
    edittingAttendees: CalendarTag[]
    edittingCtag: CalendarTag
    edittingAstag: AvailableScheduleTag
    edittingAttendeesNeedVote: CalendarTag[]
    displayMode: string
    astag: AvailableScheduleTag
    schedules: { [key: string]: Event[] }
    // addSchedules: { key: string; value: Event[] }
    // removeSchedules: { key: string; value: Event[] }
    mags: MeetingAttendeesGroup[]

    hasUnsaved: boolean

    gettingEvents: boolean
    userInfo: RoomMember
    rs: RoomSetting
    previewDuration: number
    pdm: any
    currentPossibleDates: PossibleDate[]
    dailyPossibleDates: { [key: string]: any }
    months: string[]
    ctagEmails: string[]
    dailyEvents: any
    eventsMagDic: any
    calendar_name_dic: any
    alldayHeight: number
    mouseOverCell: string
    displayMagName: string
    displayWeekSpan: number
    selectedEvent: Event
    zoomLoctags: CalendarTag[]
    teamsLoctags: CalendarTag[]
    loctags: LocationTag[]
    calendarRefreshText: string
    // updatedEventDays: string[]
    registrable_attendees: any[]
    registered_attendees: any[]
    createType: string
    voteViewType: string // calendar, mail
    auto_suggested_vote_possible_dates_max_num: number
    isOpeningEventMenu: boolean
    beforeAstag: AvailableScheduleTag
    autoloadWithIndexName: string
    fileterOnlyWritable: boolean
    sharedCalendarTagsDic: any
    sharedAstagId: string
    magEventsWeekSpan: any // {mag.name<A,B,C>: weekNum<number[]>}

    setNew(...args)
    resetAstagInfo()
    resetSchedules()
    resetSelectingAllEvents(evs: Event[], datestr)
    resetSelectingEventsDic(dic: { [key: string]: Event[] }, datestr)
    updateSchedules(schedules: any, type: string, ...args)
    resetOptionalSchedules()
    updateMags(mags: MeetingAttendeesGroup[])
    updateMagName(name: string)
    updateWeekSpan(weekSpan: number)
    updateRoomSetting(rs: RoomSetting)
    showAttendeesEdit(): boolean
    saveLocal()
    getCalList(...args): Promise<any>
    isCachedMagEvents(...args): boolean
    getMagEvents(...args): Promise<any>
    getPossibleDatesEvents(...args)
    getUsedCtagList(): CalendarTag[]
    findCurrrentCtList(): CalendarTag[]
    getCtagEvents(ctags: CalendarTag[], startTime: number, endTime: number): Promise<any>
    editStart(mag: MeetingAttendeesGroup)
    editEnd()
    deleteSelectedCtag(ctag: CalendarTag)
    resetEditting()
    editStartAstag()
    editEndAstag(astag: AvailableScheduleTag)
    editStartCtag(ctag: CalendarTag)
    editEndCtag(ctag: CalendarTag)
    getWritableCalendars()
    getOnlineLocations(...args): Promise<CalendarTag[]>
    getLocations(): Promise<LocationTag[]>
    updatePossibleDatesForVote(...args)
    getOwnerUserId(): string
    deletePossibleDates(pd: PossibleDate)
    checkOpenEventMenu()
    magsToIndexName()
}

//  データストア
let _CalendarManager: CalendarManager = reactive({
    ctList: [], // Google、MSから取得してきた全てのArray<CalendarTag>

    // 編集中の情報.（編集~確定までをここで扱います。）
    edittingMag: null,
    edittingAttendees: null,
    edittingCtag: null,
    edittingAstag: null,

    // 投票形式の場合、edittingAttendeesは投票不要な出席者です.
    // edittingAttendeesNeedVoteは投票が必要なカレンダーを付け合わせている人です.
    edittingAttendeesNeedVote: null,

    // AvailableScheduleView画面で summary(サマリー) or detail（詳細）画面の変更をコントロール.
    displayMode: `summary`,

    // 編集中のastag
    astag: null,
    schedules: null,
    // addSchedules: null,
    // removeSchedules: null,
    mags: null,

    // 保存していない変更点がある場合、true trueで戻ろうとするとwarningを表示.
    hasUnsaved: false,

    gettingEvents: false,

    userInfo: null,
    pdm: PossibleDatesManager,
    currentPossibleDates: null,
    dailyPossibleDates: null,
    months: [],

    // 取得済みのctag.email[]
    ctagEmails: [],

    // プレビューで利用するroom_setting情報
    // duration, step_sizeで利用.
    rs: null,
    previewDuration: null,

    // {MMdd: Event[]}
    dailyEvents: {},
    eventsMagDic: {},
    // { email: name }
    calendar_name_dic: {},
    alldayHeight: Const.CELL_HEIGHT * 2,
    mouseOverCell: null,
    // イベント表示中のmag
    displayMagName: null,
    // 週次カレンダー表示中のweekNum
    displayWeekSpan: null,
    // ツールチップ表示して選択中のイベント
    selectedEvent: null,

    zoomLoctags: null,
    teamsLoctags: null,
    loctags: null,
    // 週次カレンダーの更新を仰ぐテキストを表示します.
    calendarRefreshText: null,

    // アップデートを加えた日付（datestr `0114`）のみを入れておきます.
    // 変更があった場合に、ここに加えたdayLineのみを更新させます.
    // updatedEventDays: [],

    // 日程確定時にイベント登録する出席者をarrayで入れます。
    registrable_attendees: [],
    // 確定した場合に、すでにイベント登録しているユーザー.
    registered_attendees: [],

    // 作成タイプ, normal: 通常のAstagを作成します。 vote: 投票形式の作成を行います.
    createType: `normal`,
    // 投票の場合、calendar→mailの流れ
    voteViewType: `calendar`,
    // 投票の場合のmax提案件数
    auto_suggested_vote_possible_dates_max_num: -1,

    isOpeningEventMenu: false,

    beforeAstag: null,

    autoloadWithIndexName: null,

    // 書き込み可能なctagのみを表示する.
    fileterOnlyWritable: false,

    // 共有を受けている調整カレンダーのオーナーのctagsを保持します. {astag.sid: ctags}
    sharedCalendarTagsDic: {},
    // 現在編集中の共有を受けているastag.idを保持します.
    sharedAstagId: null,

    // イベント取得済みの期間を保持します. {mag.name: [202337, 202338]}
    magEventsWeekSpan: {},

    setNew(
        userInfo: RoomMember,
        us: UserSetting,
        astag: AvailableScheduleTag,
        rs: RoomSetting = null,
        refreshPdm = true,
        appo: Appointment = null
    ) {
        this.resetAstagInfo(refreshPdm)
        Logger(`CalendarManager.${funcName()} rs:${Util.output(rs)}`)
        this.userInfo = userInfo
        if (rs) {
            this.rs = { ...rs }
        } else {
            this.rs = UserSetting.toRoomSetting({ ...us })
        }
        if (astag.is_only_use_optional_schedules) {
            this.createType = `vote`
        } else {
            this.createType = `normal`
        }
        if (Util.isPresent(appo)) {
            this.previewDuration = appo.duration
        } else {
            this.previewDuration = 60
        }

        this.auto_suggested_vote_possible_dates_max_num = us.auto_suggested_vote_possible_dates_max_num || 15
        this.astag = AvailableScheduleTag.copy(astag)
        this.beforeAstag = AvailableScheduleTag.copy(astag)
        this.schedules = astag.optional_schedules
        // this.updatedEventDays = Object.keys(this.schedules)
        let mags = [...(astag.meeting_attendees_groups || [])]
        if (Util.isPresent(mags)) {
            this.displayMagName = `A`
        } else {
            if (this.showAttendeesEdit()) {
                let mag = MeetingAttendeesGroup.createDefault()
                mags = [mag]
            }
        }
        this.mags = mags

        Logger(`CM 初期値をセットしました. ${Util.output(this.astag)}`)
    },
    resetAstagInfo(refreshPdm = true) {
        this.dailyEvents = {}
        this.displayMagName = null
        this.selectedEvent = {}
        this.schedules = null
        this.edittingMag = null
        this.edittingAttendees = null
        this.edittingCtag = null
        this.mags = null
        this.createType = `normal`
        this.voteViewType = `calendar`
        this.rs = null
        this.astag = null
        this.edittingAttendeesNeedVote = null
        this.beforeAstag = null
        this.fileterOnlyWritable = false
        this.resetSchedules(refreshPdm)
    },
    resetSchedules(refreshPdm = true) {
        Logger(`cm.resetSchedules refreshPdm:${refreshPdm}`)
        this.eventsMagDic = {}
        this.magEventsWeekSpan = {}
        this.months = []
        if (this.pdm && refreshPdm) this.pdm.resetPdm()
        // this.pdm = null
        this.currentPossibleDates = null
        this.dailyPossibleDates = null
    },
    resetSelectingAllEvents(evs: Event[], datestr: string = null) {
        if (Util.isBlank(evs)) return []
        for (let _ev of evs) {
            let st = DateTime.fromSeconds(_ev.start_time)
            if (st.toFormat("MMdd") != datestr) {
                _ev.isSelecting = false
            }
        }
        return evs
    },
    // 変更日以外のすべてのisSelecting（選択中ステータス）をfalseに変更します.
    resetSelectingEventsDic(dic: { [key: string]: Event[] }, datestr: string = null) {
        if (Util.isBlank(dic)) return {}
        Object.values(dic).forEach(_opEvs => {
            let opEvs = _opEvs as Event[]
            opEvs = this.resetSelectingAllEvents(opEvs, datestr)
        })
        return dic
    },
    updateSchedules(schedules: any, type: string, date: DateTime) {
        // if (type == `add`) {
        //     this.addSchedules = schedules
        //     this.astag.optional_add_schedules = schedules
        // } else if (type == `remove`) {
        //     this.removeSchedules = schedules
        //     this.astag.optional_remove_schedules = schedules
        // } else
        if (type == `all`) {
            // Event[]形式で受け取って、
            // let adds = schedules.filter(e => e.type == `optional_add`)
            let datestr = date.toFormat(`MMdd`)

            let oSchedules = { ...(this.schedules || {}) }
            // 変更日以外のすべてのisSelecting（選択中ステータス）をfalseに変更します.
            oSchedules = this.resetSelectingEventsDic(oSchedules, datestr)

            oSchedules[datestr] = schedules
            this.schedules = oSchedules

            Logger(
                `cm.updateSchedules date:${datestr} add: ${Util.output(this.addSchedules)}, remove: ${Util.output(
                    this.removeSchedules
                )}`
            )

            let _astag = { ...this.astag }
            _astag.optional_schedules = this.schedules
            this.astag = _astag
            // this.updatedEventDays.push(datestr)
        }
        this.hasUnsaved = true

        // this.saveLocal()
    },
    resetOptionalSchedules() {
        let _astag = { ...this.astag }
        this.schedules = {}
        _astag.optional_schedules = this.schedules
        this.astag = _astag
        this.hasUnsaved = true
    },
    updateMags(mags: MeetingAttendeesGroup[]) {
        Logger(`cm.updateMags displayMagName:${this.displayMagName}`)
        this.mags = mags
        if (Util.isPresent(this.astag)) {
            this.astag.meeting_attendees_groups = mags
        }

        let _mag = mags.find(m => m.name == this.displayMagName)
        if (!_mag) {
            let _mag_a = mags.find(m => m.name == `A`)
            if (_mag_a) {
                this.displayMagName = `A`
            } else {
                this.displayMagName = null
            }
        }

        this.hasUnsaved = true

        // this.saveLocal()
    },
    // 週次イベントの表示中のmag名
    updateMagName(name: string) {
        this.displayMagName = name
    },
    updateWeekSpan(weekSpan: number) {
        this.displayWeekSpan = weekSpan
    },
    updateRoomSetting(rs: RoomSetting) {
        this.rs = rs
    },
    // カレンダー連携していなくても、magをつなぐフィールドを表示させる場合はtrueを返します.
    showAttendeesEdit(): boolean {
        if (!this.astag) return false
        if (this.astag.can_connect_calendar) return true
        let mags = this.astag.meeting_attendees_groups || []
        let validMags = mags.filter(mag => MeetingAttendeesGroup.isPresent(mag))
        if (validMags.length > 0) return true
        return false
    },
    /**
     * ローカルストレージにastagを保存します。
     */
    saveLocal() {
        let ast: AvailableScheduleTag = { ...this.astag }
        let roomStorage = new RoomStorage()
        roomStorage.saveAstag(ast)
    },
    /**
     * 連携しているカレンダー一覧を取得してきます。
     * selectedCtagsが存在する場合、そのctag.idと比較してチェックボックスにチェックとignore_alldayを設定します。
     */
    getCalList(refresh = false): Promise<any> {
        // すでに取得済みのcalListを返します.
        if (Util.isPresent(this.ctList) && !refresh) return Promise.resolve(this.ctList)

        let params = {}
        if (refresh) {
            params = { refresh: true }
        }

        return axios
            .get(`${Util.prefixUrl}/calendar_tags`, { params: params })
            .then(res => {
                let ctList: CalendarTag[] = CalendarTag.fetchFromJson(res.data.calendar_tags, "ctag")
                Logger(`cm.getCalList: ${Util.output(ctList)}`)

                // 今までどおり代入.
                this.ctList = ctList
                if (!this.edittingMag) this.resetEditting()

                return ctList
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return []
            })
    },
    getPossibleDatesEvents(
        needRefresh: boolean = true,
        month: string = null,
        room: Room = null,
        week: string = null
    ): Promise<any> {
        // let magNum = (this.astag.meeting_attendees_groups || []).length
        let calendarShowType = this.pdm.getCalendarShowType(this.rs, false)
        Logger(
            `CM.getPossibleDatesEvents needRefresh:${needRefresh}, month:${month}, week:${week}, createType:${
                this.createType
            }, rs:${Util.output(this.rs)}, calendarShowType:${calendarShowType}`
        )
        if (!this.astag) return
        if (this.createType == `vote` || (this.astag && this.astag.is_only_use_optional_schedules)) {
            this.gettingEvents = false
            return
        }

        // gtagClick(`PossibleDates取得.`, `またはの数:${magNum}`)

        // 取得中のため、二度叩き防止.
        // if (this.gettingEvents) return Promise.resolve(null)
        this.gettingEvents = true

        let _astag = { ...this.astag }
        let rs = { ...this.rs }
        if (_astag.id == `newId`) {
            _astag.id = null
        }

        let months = null
        let weeks = null

        if (calendarShowType == `weekly`) {
            weeks = week ? [week] : [CalendarUtil.toWeekSpan(DateTime.local())]
            months = null
        } else {
            months = month ? [month] : [CalendarUtil.toCalId(DateTime.local())]
            weeks = null
        }

        if (needRefresh) {
            this.currentPossibleDates = null
            this.dailyPossibleDates = null
        }

        let _duration = 60
        if (Util.isPresent(this.rs)) {
            _duration = this.previewDuration
        }
        if (this.createType == `normal` && !Util.isRoomSettingsPreview()) {
            // 日程候補を表示する必要がないので、
            rs = null
        }
        let max_bookings_num = 1
        if (room && room.max_bookings_num > 1) {
            max_bookings_num = room.max_bookings_num
        }
        let locationName = _astag.location_name

        return AvailableScheduleTag.getPossibleDatesPreview(
            _astag,
            this.userInfo,
            _duration,
            true,
            locationName,
            rs,
            months,
            needRefresh,
            max_bookings_num,
            room,
            weeks,
            calendarShowType
        ).then(pdm => {
            this.gettingEvents = false
            if (!pdm) {
                Logger(`CalendarManager.getPossibleDatesEvents pdm not found.`)
                // Notice.message = `うまく取得できませんでした。再度更新してください。`
                return null
            }
            // 取得内容を調整ページの候補日時と同じUIにはめます。
            // すでに別のastagを利用しているため、取得したpdmのデータは捨てます.
            if (this.astag && this.astag.id && this.astag.id != `newId`) {
                Logger(`CalendarManager.getPossibleDatesEvents astag.id:${this.astag?.id} pdm.astag_id:${pdm?.astag_id}`)
                if (this.astag.id != pdm.astag_id) return
            }
            this.pdm = pdm as typeof PossibleDatesManager
            let gettableItems = this.pdm.getGettableItems()

            let posDates = this.pdm.possibleDates
            Logger(
                `CalendarManager.getPossibleDatesEvents calendarShowType:${calendarShowType}, gettableItems:${Util.output(
                    gettableItems
                )} posDates:${Util.output(posDates)}`
            )
            if ((posDates || []).length == 0 && gettableItems.length > 0) {
                if (calendarShowType == `weekly`) {
                    this.getPossibleDatesEvents(false, null, null, gettableItems[0])
                } else {
                    this.getPossibleDatesEvents(false, gettableItems[0])
                }
            } else {
                if (this.createType == `vote` || _astag.is_only_use_optional_schedules) {
                    // let pds = this.pdm.possibleDates
                    let dailyPds = this.pdm.dailyPossibleDates
                    let stepSize = CalendarUtil.selectDisplayMeetingTimeArr.find(dic => dic.time == this.rs.step_size_type)
                    let meetingDuration = this.previewDuration

                    // let evsDic = dailyPds || {}
                    let pevs = []

                    let totalCounter = 0
                    // let maxNum = this.auto_suggested_vote_possible_dates_max_num
                    // let dailyMaxNum = 2
                    const name = this.displayMagName || `A`
                    Object.entries(dailyPds).forEach(([datestr, evsDic]) => {
                        let dailyCounter = 0
                        let dailyPevs = []
                        let userId = this.getOwnerUserId()
                        let _dic = evsDic[userId].group_events_dic[name]
                        let pds = [...(_dic.possible_events || [])]
                        Logger(`${funcName()} VOTE  [${datestr}](${(pds || []).length}) = ${Util.output(pds)} `)

                        // 取得してきたpossible_datesを幅広から分割します.
                        for (let _longpd of pds) {
                            let newPds = Event.toSeparatePossibleDates(_longpd, meetingDuration, stepSize.number) || []
                            for (let _pd of newPds) {
                                // 合計が最大に達したら残りは捨てます.
                                // if (dailyCounter >= dailyMaxNum) continue
                                // if (totalCounter >= maxNum) break

                                pevs.push(_pd)
                                dailyPevs.push(_pd)

                                // dailyCounter++
                                // totalCounter++
                            }
                            // Array.prototype.push.apply(pevs, newPds)
                        }
                        if (Util.isPresent(dailyPevs)) {
                            this.updatePossibleDatesForVote(dailyPevs, datestr)
                        }
                    })

                    this.currentPossibleDates = pevs
                    this.pdm.possibleDates = pevs
                    // this.pdm.dailyPossibleDates = dailyPevs
                    // this.dailyPossibleDates = _dailyDic
                } else {
                    this.currentPossibleDates = this.pdm.possibleDates
                    this.dailyPossibleDates = this.pdm.dailyPossibleDates
                }
            }

            // this.dailyPossibleDates = this.pdm.dailyPossibleDates
            if (this.astag) {
                this.astag.systemUpdatedAt = Util.getSec()
            }

            Array.prototype.push.apply(this.months, pdm.months || [])
            return pdm
        })
    },
    getUsedCtagList(): CalendarTag[] {
        let mags = this.astag.meeting_attendees_groups as MeetingAttendeesGroup[]
        let ctags = []
        let ctagEmails = []
        for (let _mag of mags) {
            let _atts = _mag.required_attendees
            for (let _att of _atts) {
                if (!ctagEmails.includes(_att.email)) {
                    ctags.push(_att)
                    ctagEmails.push(_att.email)
                }
            }
        }
        return ctags
    },
    /**
     * 現在のastagの共有しているカレンダーとそうでない場合の外部カレンダーをよしなに返却します.
     * @returns
     */
    findCurrrentCtList(): CalendarTag[] {
        if (this.astag.is_shared) {
            let ctags = this.sharedCalendarTagsDic[this.astag.id]
            Logger(`${funcName()} is_shared Astag1. ctags:${(ctags || []).length}`)
            if (Util.isBlank(ctags)) return
            return [...ctags]
        }

        if (this.sharedAstagId) {
            let ctags = this.sharedCalendarTagsDic[this.sharedAstagId]
            Logger(`${funcName()} is_shared Astag2. ctags:${(ctags || []).length}`)
            if (Util.isBlank(ctags)) return
            return [...ctags]
        }

        if (Util.isBlank(this.ctList)) return
        return [...this.ctList]
    },
    /**
     *
     * @param startTime [DateTime]
     * @param magName [string] A,B,C
     * @param weekSpan [number] 202337
     * @returns
     */
    isCachedMagEvents(startTime: DateTime, magName: string = null, weekSpan: number = null) {
        if (Util.isBlank(this.magEventsWeekSpan)) return false

        let weekNum
        if (Util.isPresent(weekSpan)) {
            weekNum = weekSpan
        } else {
            // let startWeekNum = `${startTime.weekNumber}`.padStart(2, "0")
            // weekNum = Number(`${startTime.year}${startWeekNum}`)
            weekNum = Number(CalendarUtil.toWeekSpan(startTime))
        }

        if (!magName) magName = this.displayMagName
        // Logger(
        //     `cm.isCachedMagEvents magName:${magName} weekNum:${weekNum} magEventsWeekSpan:${Util.output(this.magEventsWeekSpan)}`
        // )
        let magSpan = this.magEventsWeekSpan[magName] || []
        if (magSpan.includes(weekNum)) {
            Logger(
                `cm.isCachedMagEvents Find Cache. magName:${magName} weekNum:${weekNum}, magEventsWeekSpan:${Util.output(
                    this.magEventsWeekSpan
                )}`
            )
            return true
        }
        Logger(
            `cm.isCachedMagEvents Not Find Cache. magName:${magName} weekNum:${weekNum}, magEventsWeekSpan:${Util.output(
                this.magEventsWeekSpan
            )}`
        )
        return false
    },
    getMagEvents(startTime: DateTime, magName: string = null, endTime: DateTime = null, refresh: boolean = false): Promise<any> {
        Logger(`cm.getMagEvents startTime:${startTime}, endTime:${endTime}, magName:${magName}, refresh:${refresh}`)
        if (!startTime) {
            startTime = DateTime.local()
        }
        let et = endTime || startTime?.plus({ month: 1 })?.endOf(`month`)

        let _astag = { ...this.astag }
        // すでに取得済みの場合、そのまま返します.
        if (!refresh && this.isCachedMagEvents(startTime, magName)) return Promise.resolve(this.eventsMagDic)

        if (this.gettingEvents) return Promise.resolve(null)
        this.gettingEvents = true

        if (_astag.id == `newId`) _astag.id = null

        const params = {
            available_time: _astag,
            start_time: startTime?.toSeconds(),
            end_time: et?.toSeconds(),
            mag_name: magName,
        }
        Logger(
            `cm.getMagEvents magEventsの取得を開始しました. start_time:${startTime?.toISO()}, end_time:${et?.toISO()}, mag_name:${magName}`
        )

        return axios
            .post(`${Util.prefixUrl}/available_schedule_tags/events`, params)
            .then(result => {
                // this.gettingEvents = false
                if (this.astag.id && this.astag.id != `newId`) {
                    // astagを切り替えたため、取得したイベント情報を破棄します.
                    if (this.astag.id != result.data.available_time_id) return
                }
                Logger(`cm.getMagEvents result: ${Util.output(result.data)}`)
                /**
                 * 'a': {
                 *      'name': 'A'
                        'team_name': 'Aチーム',
                        'description': 'Aチームが担当します',
                        'daily_events': {
                            'mmdd': [
                                PDM_EVENT_HASH,
                                PDM_EVENT_HASH,
                            ]
                        }
                 *   }
                 */
                let sharedCtags = result.data.shared_calendar_tags
                if (Util.isPresent(sharedCtags)) {
                    this.sharedCalendarTagsDic[result.data.available_time_id] = sharedCtags
                }
                const evDic = result.data.events_mag_dic
                if (!refresh && Util.isPresent(this.eventsMagDic)) {
                    // ドッキングします.
                    Object.entries(evDic).forEach(([key, value]) => {
                        if (magName && magName != key) return

                        let _value = value as any
                        let _evs = _value.daily_events
                        let evs = this.eventsMagDic[key].daily_events
                        Object.entries(_evs).forEach(([date, events]) => {
                            let _events = events as any
                            let _evs = evs[date] || []
                            Array.prototype.push.apply(_evs, _events)
                            evs[date] = _evs
                        })
                    })
                } else {
                    this.eventsMagDic = evDic
                }
                let magWeekSpan = this.magEventsWeekSpan[magName]
                if (Util.isBlank(magWeekSpan)) {
                    magWeekSpan = result.data.week_span
                } else {
                    Array.prototype.push.apply(magWeekSpan, result.data.week_span)
                }
                this.magEventsWeekSpan[magName] = magWeekSpan
                this.calendar_name_dic = result.data.calendar_name_dic

                this.gettingEvents = false
                return this.eventsMagDic
            })
            .catch(err => {
                this.gettingEvents = false
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                // Notice.message = `カレンダー情報をうまく取得できませんでした。`
                NotificationControl.showErrorMessage(err)
                return {}
            })
    },
    /**
     * Ctagsを利用したpossible_datesを叩きます。
     */
    getCtagEvents(ctags: CalendarTag[], startTime: number, endTime: number): Promise<any> {
        // const _ctags = [...(ctags || [])]
        Logger(`CalendarManager.getCtagEvents 選択しているctags: ${Util.output(ctags)}`)

        const params = { calendar_tags: ctags }
        if (startTime) {
            params[`start_time`] = startTime
        }
        if (endTime) {
            params[`end_time`] = endTime
        }

        return axios
            .post(`${Util.prefixUrl}/calendar_tags/events`, params)
            .then(result => {
                Logger(`CalendarManager.getCtagEvents result: ${Util.output(result.data)}`)
                const _dailyEvents = result.data.daily_events
                let dailyEvents = {}
                Object.entries(_dailyEvents).forEach(([date, events]) => {
                    let _events: Event[] = events as any
                    let evs = []
                    for (let ev of _events) {
                        ev.systemUpdatedAt = DateTime.local().toMillis() + ev.start_time
                        evs.push(ev)
                    }
                    let devs = this.dailyEvents[date] || []
                    Array.prototype.push.apply(devs, evs)
                    dailyEvents[date] = devs
                })
                this.dailyEvents = dailyEvents

                return dailyEvents || {}
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                // Notice.message = `カレンダー情報をうまく取得できませんでした。`
                NotificationControl.showErrorMessage(err)
                return {}
            })
    },
    /**
     * グループの編集を開始します。
     * @param mag
     */
    editStart(mag: MeetingAttendeesGroup): void {
        if (mag) {
            this.edittingMag = mag
            this.edittingAttendees = mag.required_attendees || []
        } else {
            this.resetEditting()
        }
    },
    /**
     * 編集を確定します。
     */
    editEnd(): MeetingAttendeesGroup {
        let mag = this.edittingMag
        mag.required_attendees = this.edittingAttendees
        mag.systemUpdatedAt = Util.getSec()
        this.resetEditting()
        return mag
    },
    resetEditting() {
        this.edittingMag = MeetingAttendeesGroup.createDefault()
        this.edittingAttendees = this.edittingMag.required_attendees
        this.edittingCtag = null
    },
    deleteSelectedCtag(ctag: CalendarTag): void {
        this.edittingAttendees = this.edittingAttendees.filter(e => e.email != ctag.email)
        this.edittingMag.required_attendees = this.edittingAttendees
    },
    /**
     * Astagの無視キーワードなどを設定します.
     */
    editStartAstag() {
        Logger(`CM#editStartAstag ${Util.output(this.astag)}`)
        if (!this.astag) return
        this.edittingCtag = null
        this.edittingAstag = { ...this.astag }

        return this.edittingAstag
    },
    editEndAstag(astag: AvailableScheduleTag) {
        this.astag = astag
        this.edittingAstag = null
    },
    /**
     * 個々のctagを編集を開始します。
     * @param ctag
     */
    editStartCtag(ctag: CalendarTag) {
        let ct = this.edittingAttendees.find(e => e.email == ctag.email)
        Logger(`個々のctagを編集を開始します。: ${Util.output(ct)}`)
        if (!ct) return null

        ct.systemUpdatedAt = Util.getSec()
        this.edittingCtag = ct
        return ct
    },
    editEndCtag(ctag: CalendarTag) {
        let ctags = []
        for (let ct of this.edittingAttendees) {
            if (ct.email == ctag.email) {
                Object.assign(ct, ctag)
                ct.systemUpdatedAt = Util.getSec()
            }
            ctags.push(ct)
        }
        Logger(`editEndCtag: 編集を終了します。 ${Util.output(ctags)}`)
        this.edittingAttendees = ctags
        if (this.edittingMag) this.edittingMag.required_attendees = this.edittingAttendees
        return ctags
    },
    // 取得済みカレンダーに対して書込可能なカレンダーのみフィルターします。
    getWritableCalendars() {
        let ctList = this.findCurrrentCtList() || []
        return ctList.filter(cal => cal.writable)
    },
    getOnlineLocations(providers = [`zoom`]): Promise<CalendarTag[]> {
        if (JSON.stringify(providers) === JSON.stringify([`zoom`]) && this.zoomLoctags) {
            return Promise.resolve(this.zoomLoctags)
        }
        if (JSON.stringify(providers) === JSON.stringify([`teams`]) && this.teamsLoctags) {
            return Promise.resolve(this.teamsLoctags)
        }

        return LocationTag.getOnlineLocations(providers).then(_loctags => {
            Logger(`locationを取得しました:[${Util.output(providers)}] ${Util.output(_loctags)}`)
            if (_loctags) {
                if (providers.includes(`zoom`)) {
                    this.zoomLoctags = _loctags.filter(_l => _l.provider == `zoom`)
                }
                if (providers.includes(`teams`)) {
                    this.teamsLoctags = _loctags.filter(_l => [`ms_for_link`, `teams_for_link`].includes(_l.provider))
                }
            }
            return this.zoomLoctags
        })
    },
    getLocations(): Promise<LocationTag[]> {
        if (this.loctags) return Promise.resolve(this.loctags)
        return LocationTag.getLocations().then(res => {
            if (res) {
                this.loctags = res
            }
            return this.loctags
        })
    },
    /**
     *
     * 更新する情報 pdm.possibleDates
     * @param posEvents
     * @param datestr
     */
    // 投票形式の場合は、可能候補を選ぶため、PossibleDatesとdailyPossibleDatesを更新していきます.
    updatePossibleDatesForVote(posEvents: PossibleDate[], datestr: string) {
        let evsDic = (this.dailyPossibleDates || {})[datestr] || {}
        const name = this.displayMagName || `A`
        Logger(`${funcName()} name:${name} datestr:${datestr}`)

        if (Util.isPresent(evsDic)) {
            let _dic = null
            let userId = this.getOwnerUserId()
            _dic = evsDic[userId].group_events_dic[name]
            Logger(`${funcName()} _dic:${Util.output(_dic)}`)

            if (_dic) {
                let pevs = [..._dic.possible_events] || []
                for (let pd of posEvents) {
                    if (pevs.some(pev => PossibleDate.isSame(pev, pd))) {
                    } else {
                        pevs.push(pd)
                    }
                }
                pevs = Util.sortBy(pevs, `start_time`)
                // Array.prototype.push.apply(pevs, posEvents)
                // this.possibleEvents = pevs
                _dic.possible_events = pevs
                let userId = this.getOwnerUserId()
                this.dailyPossibleDates[datestr][userId].group_events_dic[name] = _dic
            }
        } else {
            Logger(`${funcName()} evsDicがないためつくります. ${Util.output(this.dailyPossibleDates)}`)

            let _dic = {}
            let userId = this.getOwnerUserId()
            _dic[userId] = { group_events_dic: {} }
            _dic[userId].group_events_dic[name] = { possible_events: posEvents }
            this.dailyPossibleDates = this.dailyPossibleDates ? this.dailyPossibleDates : {}
            this.dailyPossibleDates[datestr] = _dic
        }
        if (Util.isBlank(this.pdm)) {
            this.pdm = PossibleDatesManager
        }
        let pds = [...(this.pdm.possibleDates || [])]
        for (let _pd of posEvents) {
            if (pds.some(pev => PossibleDate.isSame(pev, _pd))) {
            } else {
                pds.push(_pd)
            }
        }
        pds = Util.sortBy(pds, `start_time`)
        this.pdm.possibleDates = pds
        this.currentPossibleDates = pds
        Logger(`CalendarManager#updatePossibleDatesForVote currentPossibleDates ${Util.output(this.currentPossibleDates)}`)

        // Array.prototype.push.apply(this.pdm.possibleDates, posEvents)
    },
    getOwnerUserId() {
        let userId = this.userInfo.user_id
        if (this.astag.is_shared) {
            // シェアされている場合は、オーナーのIDを利用します.
            userId = this.astag.owner.user_id
        }
        return userId
    },
    deletePossibleDates(pd: PossibleDate) {
        Logger(`deletePossibleDates: ${Util.output(pd)}`)
        let pds = [...this.pdm.possibleDates]
        Logger(`pds total: ${pds.length}`)
        let datestr = pd.date_format
        let userId = this.getOwnerUserId()
        const name = this.displayMagName || `A`
        let _dic = (this.dailyPossibleDates || {})[datestr][userId].group_events_dic[name]
        let dic = { ..._dic }
        dic.possible_events = dic.possible_events.filter(_pd => !PossibleDate.isSame(pd, _pd))

        this.dailyPossibleDates[datestr][userId].group_events_dic[name].possible_events = dic.possible_events

        pds = pds.filter(_pd => !PossibleDate.isSame(pd, _pd))
        this.pdm.possibleDates = pds
        this.currentPossibleDates = pds
        Logger(`AFTER pds total: ${pds.length}`)
    },
    checkOpenEventMenu() {
        if (Util.isBlank(this.selectedEvent)) {
            this.isOpeningEventMenu = false
            return
        }
        sleep(100).then(_ => {
            let openMenu = $(`.btn-group.dropdown.open`)[0]
            Logger(`${funcName()} openMenu:${openMenu}`)
            if (Util.isPresent(openMenu)) {
                this.isOpeningEventMenu = true
            } else {
                this.isOpeningEventMenu = false
            }
        })
    },
    magsToIndexName(): string {
        return Util.output(this.mags)
    },
})
export default _CalendarManager
